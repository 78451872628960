import { HYDRATE } from "next-redux-wrapper";
import { ActionsArticleDate } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeArticleDate(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeArticleDate };
	case ActionsArticleDate.LOAD_GET_ARTICLE_DATE:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsArticleDate.RES_GET_ARTICLE_DATE:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsArticleDate.ERR_GET_ARTICLE_DATE:
		return {
			...state,
			status: "fetch_error",
			message: "Error...",
			data: { data: [] },
		};
	default:
		return { ...state };

	}

}

export default storeArticleDate;
