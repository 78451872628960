export const ActionsArticleAuthor = {
	GET_ARTICLE_AUTHOR: "get_article_author",
	LOAD_GET_ARTICLE_AUTHOR: "load_get_article_author",
	RES_GET_ARTICLE_AUTHOR: "res_get_article_author",
	ERR_GET_ARTICLE_AUTHOR: "err_get_article_author",
	HYDRATE: "HYDRATE",
};

export function failureArticleAuthor(err) {

	return {
		type: ActionsArticleAuthor.ERR_GET_ARTICLE_AUTHOR,
		err,
	};

}

export function loadArticleAuthor(params = {}) {

	return {
		type: ActionsArticleAuthor.GET_ARTICLE_AUTHOR,
		...params,
	};

}

export function loadGetArticleAuthor(data) {

	return {
		type: ActionsArticleAuthor.LOAD_GET_ARTICLE_AUTHOR,
		data,
	};

}

export function loadArticleAuthorSuccess(data) {

	return {
		type: ActionsArticleAuthor.RES_GET_ARTICLE_AUTHOR,
		data,
	};

}
