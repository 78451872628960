import { HYDRATE } from "next-redux-wrapper";
import { ActionsSynergyMedia } from "./actions";

const initialState = {
	dataFD: [], dataGB: [], statusFD: "fetch_init", statusGB: "fetch_init", message: "",
};
function storeSynergyMedia(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeSynergyMedia };
	case ActionsSynergyMedia.LOAD_GET_SYNERGY_MEDIA:
		if (action.typeSynergy === "fd") {

			return { ...state, dataFD: action.data, statusFD: "fetch_loading" };

		}
		return { ...state, dataGB: action.data, statusGB: "fetch_loading" };
	case ActionsSynergyMedia.RES_GET_SYNERGY_MEDIA:
		if (action.typeSynergy === "fd") {

			return { ...state, dataFD: action.data, statusFD: "fetch_result" };

		}
		return { ...state, dataGB: action.data, statusGB: "fetch_result" };
	case ActionsSynergyMedia.ERR_GET_SYNERGY_MEDIA:
		if (action.typeSynergy === "fd") {

			return {
				...state,
				dataFD: action,
				statusFD: "fetch_error",
				message: "Error...",
			};

		}
		return {
			...state,
			dataGB: action,
			statusGB: "fetch_error",
			message: "Error...",
		};
	default:
		return { ...state };

	}

}

export default storeSynergyMedia;
