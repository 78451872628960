export const ActionsArticleDetail = {
	GET_ARTICLE_DETAIL: "get_article_detail",
	LOAD_GET_ARTICLE_DETAIL: "load_get_article_detail",
	RES_GET_ARTICLE_DETAIL: "res_get_article_detail",
	ERR_GET_ARTICLE_DETAIL: "err_get_article_detail",
	HYDRATE: "HYDRATE",
};

export function failureArticleDetail(err) {

	return {
		type: ActionsArticleDetail.ERR_GET_ARTICLE_DETAIL,
		err,
	};

}

export function loadArticleDetail(params = {}) {

	return {
		type: ActionsArticleDetail.GET_ARTICLE_DETAIL,
		...params,
	};

}

export function loadGetArticleDetail(params = {}) {

	return {
		type: ActionsArticleDetail.LOAD_GET_ARTICLE_DETAIL,
		...params,
	};

}

export function loadArticleDetailSuccess(data) {

	return {
		type: ActionsArticleDetail.RES_GET_ARTICLE_DETAIL,
		data,
	};

}
