export const ActionsQuiz = {
	GET_QUIZ: "get_quiz",
	LOAD_GET_QUIZ: "load_get_quiz",
	RES_GET_QUIZ: "res_get_quiz",
	ERR_GET_QUIZ: "err_get_quiz",
	HYDRATE: "HYDRATE",
};

export function failureQuiz(err) {

	return {
		type: ActionsQuiz.ERR_GET_QUIZ,
		err,
	};

}

export function loadQuiz(params = {}) {

	return {
		type: ActionsQuiz.GET_QUIZ,
		...params,
	};

}

export function loadGetQuiz(params = {}) {

	return {
		type: ActionsQuiz.LOAD_GET_QUIZ,
		...params,
	};

}

export function loadQuizSuccess(data) {

	return {
		type: ActionsQuiz.RES_GET_QUIZ,
		data,
	};

}
