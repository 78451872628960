export const ActionsYoutubeVideo = {
	GET_YOUTUBE_VIDEO: "get_youtube_video",
	LOAD_GET_YOUTUBE_VIDEO: "load_get_youtube_video",
	RES_GET_YOUTUBE_VIDEO: "res_get_youtube_video",
	ERR_GET_YOUTUBE_VIDEO: "err_get_youtube_video",
	HYDRATE: "HYDRATE",
};

export function failureYoutubeVideo(err) {

	return {
		type: ActionsYoutubeVideo.ERR_GET_YOUTUBE_VIDEO,
		err,
	};

}

export function loadYoutubeVideo(params = {}) {

	return {
		type: ActionsYoutubeVideo.GET_YOUTUBE_VIDEO,
		...params,
	};

}

export function loadGetYoutubeVideo(params = {}) {

	return {
		type: ActionsYoutubeVideo.LOAD_GET_YOUTUBE_VIDEO,
		...params,
	};

}

export function loadYoutubeVideoSuccess(data) {

	return {
		type: ActionsYoutubeVideo.RES_GET_YOUTUBE_VIDEO,
		data,
	};

}
