import {
	call, put, takeEvery,
} from "redux-saga/effects";
import {
	ActionsMenuNavbar,
	failureMenuNavbar,
	loadMenuNavbarSuccess,
	loadGetMenuNavbar,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataMenuNavbar(params = {}) {

	try {

		yield put(loadGetMenuNavbar());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getMenuNavbar`;
		const headers = { ...Env.HEADERS, page: params.page };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const dataMenuNavbar = yield response.json();

		if (response.status !== 200) {

			yield put(failureMenuNavbar(yield response.statusText));

		} else {

			yield put(loadMenuNavbarSuccess(yield dataMenuNavbar));

		}

	} catch (err) {

		yield put(failureMenuNavbar(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsMenuNavbar.GET_MENU_NAVBAR, loadDataMenuNavbar);

}
