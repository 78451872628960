export const ActionsSearchPage = {
	GET_SEARCH_PAGE: "get_search_page",
	LOAD_GET_SEARCH_PAGE: "load_get_search_page",
	RES_GET_SEARCH_PAGE: "res_get_search_page",
	ERR_GET_SEARCH_PAGE: "err_get_search_page",
	HYDRATE: "HYDRATE",
};

export function failureSearchPage(err) {

	return {
		type: ActionsSearchPage.ERR_GET_SEARCH_PAGE,
		err,
	};

}

export function loadSearchPage(params = {}) {

	return {
		type: ActionsSearchPage.GET_SEARCH_PAGE,
		...params,
	};

}

export function loadGetSearchPage(params = {}) {

	return {
		type: ActionsSearchPage.LOAD_GET_SEARCH_PAGE,
		...params,
	};

}

export function loadSearchPageSuccess(data) {

	return {
		type: ActionsSearchPage.RES_GET_SEARCH_PAGE,
		data,
	};

}
