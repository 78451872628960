import {
	call, put, takeEvery, race, delay,
} from "redux-saga/effects";
import {
	ActionsArticleParenting,
	failureArticleParenting,
	loadArticleParentingSuccess,
	loadGetArticleParenting,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataArticleParenting(params = {}) {

	try {

		yield put(loadGetArticleParenting());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getArticleParenting`;
		const headers = { ...Env.HEADERS, page: params.page };
		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureArticleParenting(yield "Error Timeout..."));

		} else {

			const dataArticleParenting = yield response.json();

			if (response.status !== 200) {

				yield put(failureArticleParenting(yield response.statusText));

			} else {

				yield put(loadArticleParentingSuccess(yield dataArticleParenting));

			}

		}

	} catch (err) {

		yield put(failureArticleParenting(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsArticleParenting.GET_ARTICLE_PARENTING, loadDataArticleParenting);

}
