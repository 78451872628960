import { HYDRATE } from "next-redux-wrapper";
import { ActionsMenuDrawer } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeMenuDrawer(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeMenuDrawer };
	case ActionsMenuDrawer.LOAD_GET_MENU_DRAWER:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsMenuDrawer.RES_GET_MENU_DRAWER:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsMenuDrawer.ERR_GET_MENU_DRAWER:
		return {
			...state,
			status: "fetch_error",
			message: "Error...",
		};
	default:
		return { ...state };

	}

}

export default storeMenuDrawer;
