export const ActionsArticleWork = {
	GET_ARTICLE_WORK: "get_article_work",
	LOAD_GET_ARTICLE_WORK: "load_get_article_work",
	RES_GET_ARTICLE_WORK: "res_get_article_work",
	ERR_GET_ARTICLE_WORK: "err_get_article_work",
	HYDRATE: "HYDRATE",
};

export function failureArticleWork(err) {

	return {
		type: ActionsArticleWork.ERR_GET_ARTICLE_WORK,
		err,
	};

}

export function loadArticleWork(params = {}) {

	return {
		type: ActionsArticleWork.GET_ARTICLE_WORK,
		...params,
	};

}

export function loadGetArticleWork(params = {}) {

	return {
		type: ActionsArticleWork.LOAD_GET_ARTICLE_WORK,
		...params,
	};

}

export function loadArticleWorkSuccess(data) {

	return {
		type: ActionsArticleWork.RES_GET_ARTICLE_WORK,
		data,
	};

}
