export const ActionsArticlePopular = {
	GET_ARTICLE_POPULAR: "get_article_popular",
	LOAD_GET_ARTICLE_POPULAR: "load_get_article_popular",
	RES_GET_ARTICLE_POPULAR: "res_get_article_popular",
	ERR_GET_ARTICLE_POPULAR: "err_get_article_popular",
	HYDRATE: "HYDRATE",
};

export function failureArticlePopular(err) {

	return {
		type: ActionsArticlePopular.ERR_GET_ARTICLE_POPULAR,
		err,
	};

}

export function loadArticlePopular(params = {}) {

	return {
		type: ActionsArticlePopular.GET_ARTICLE_POPULAR,
		...params,
	};

}

export function loadGetArticlePopular(params = {}) {

	return {
		type: ActionsArticlePopular.LOAD_GET_ARTICLE_POPULAR,
		...params,
	};

}

export function loadArticlePopularSuccess(data) {

	return {
		type: ActionsArticlePopular.RES_GET_ARTICLE_POPULAR,
		data,
	};

}
