import { combineReducers } from "redux";
import { storeSelf } from "@fdn/profile_store";
import storeArticlePopular from "../ArticlePopular/lib/store";
import storeArticleZone from "../ArticleZone/lib/store";
import storeArticleMost from "../ArticleMost/lib/store";
import storeArticleCampaign from "../ArticleCampaign/lib/store";
import storeArticleParenting from "../ArticleParenting/lib/store";
import storeDiscoverVideo from "../DiscoverVideo/lib/store";
import storeArticleEducation from "../ArticleEducation/lib/store";
import storeArticleRelation from "../ArticleRelation/lib/store";
import storeArticleWork from "../ArticleWork/lib/store";
import storeArticleDetail from "../ArticleDetail/lib/store";
import storeArticlePreview from "../ArticlePreview/lib/store";
import storeArticleRelated from "../ArticleRelated/lib/store";
import storeArticleCategory from "../ArticleCategory/lib/store";
import storeArticleTags from "../ArticleTags/lib/store";
import storeArticleDate from "../ArticleDate/lib/store";
import storeArticleAuthor from "../ArticleAuthor/lib/store";
import storeQuiz from "../Quiz/lib/store";
import storeReview from "../Review/lib/store";
import storeCommentArticle from "../CommentArticle/lib/store";
import storeSearchArticles from "../SearchArticles/lib/store";
import storeMenuNavbar from "../MenuNavbar/lib/store";
import storeMenuDrawer from "../MenuDrawer/lib/store";
import storeSearchPage from "../SearchPage/lib/store";
import storeWallet from "../AlloWallet/lib/store";
import storePoint from "../AlloPoint/lib/store";
import storeSynergyMedia from "../SynergyMedia/lib/store";
import storeYoutubeVideo from "../YoutubeVideo/lib/store";
import storeArticleMidlife from "../ArticleMidlife/lib/store";

const rootReducer = combineReducers({

	storeSelf,
	storeArticlePopular,
	storeArticleZone,
	storeArticleMost,
	storeArticleCampaign,
	storeArticleParenting,
	storeDiscoverVideo,
	storeArticleEducation,
	storeArticleRelation,
	storeArticleWork,
	storeArticleDetail,
	storeArticlePreview,
	storeArticleRelated,
	storeArticleCategory,
	storeArticleTags,
	storeArticleDate,
	storeArticleAuthor,
	storeArticleMidlife,
	storeQuiz,
	storeReview,
	storeCommentArticle,
	storeSearchArticles,
	storeMenuNavbar,
	storeMenuDrawer,
	storeSearchPage,
	storeWallet,
	storePoint,
	storeSynergyMedia,
	storeYoutubeVideo,
});

export default rootReducer;
