import {
	call, put, takeEvery, race, delay,
} from "redux-saga/effects";
import {
	ActionsArticleWork,
	failureArticleWork,
	loadArticleWorkSuccess,
	loadGetArticleWork,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataArticleWork(params = {}) {

	try {

		yield put(loadGetArticleWork());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getArticleWork`;
		const headers = { ...Env.HEADERS, limit: params.limit || 4 };
		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureArticleWork(yield "Error Timeout..."));

		} else {

			const dataArticleWork = yield response.json();

			if (response.status !== 200) {

				yield put(failureArticleWork(yield response.statusText));

			} else {

				yield put(loadArticleWorkSuccess(yield dataArticleWork));

			}

		}

	} catch (err) {

		yield put(failureArticleWork(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsArticleWork.GET_ARTICLE_WORK, loadDataArticleWork);

}
