export const ActionsArticleMidlife = {
	GET_ARTICLE_MIDLIFE: "get_article_midlife",
	LOAD_GET_ARTICLE_MIDLIFE: "load_get_article_midlife",
	RES_GET_ARTICLE_MIDLIFE: "res_get_article_midlife",
	ERR_GET_ARTICLE_MIDLIFE: "err_get_article_midlife",
	HYDRATE: "HYDRATE",
};

export function failureArticleMidlife(err) {

	return {
		type: ActionsArticleMidlife.ERR_GET_ARTICLE_MIDLIFE,
		err,
	};

}

export function loadArticleMidlife(params = {}) {

	return {
		type: ActionsArticleMidlife.GET_ARTICLE_MIDLIFE,
		...params,
	};

}

export function loadGetArticleMidlife(params = {}) {

	return {
		type: ActionsArticleMidlife.LOAD_GET_ARTICLE_MIDLIFE,
		...params,
	};

}

export function loadArticleMidlifeSuccess(data) {

	return {
		type: ActionsArticleMidlife.RES_GET_ARTICLE_MIDLIFE,
		data,
	};

}
