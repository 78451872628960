export const ActionsArticleRelated = {
	GET_ARTICLE_RELATED: "get_article_related",
	LOAD_GET_ARTICLE_RELATED: "load_get_article_related",
	RES_GET_ARTICLE_RELATED: "res_get_article_related",
	ERR_GET_ARTICLE_RELATED: "err_get_article_related",
	HYDRATE: "HYDRATE",
};

export function failureArticleRelated(err) {

	return {
		type: ActionsArticleRelated.ERR_GET_ARTICLE_RELATED,
		err,
	};

}

export function loadArticleRelated(params = {}) {

	return {
		type: ActionsArticleRelated.GET_ARTICLE_RELATED,
		...params,
	};

}

export function loadGetArticleRelated(params = {}) {

	return {
		type: ActionsArticleRelated.LOAD_GET_ARTICLE_RELATED,
		...params,
	};

}

export function loadArticleRelatedSuccess(data) {

	return {
		type: ActionsArticleRelated.RES_GET_ARTICLE_RELATED,
		data,
	};

}
