import { HYDRATE } from "next-redux-wrapper";
import { ActionsArticleMidlife } from "./actions";

const initialState = { data: [], status: "fetch_init", message: "" };

function storeArticleMidlife(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeArticleMidlife };
	case ActionsArticleMidlife.LOAD_GET_ARTICLE_MIDLIFE:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsArticleMidlife.RES_GET_ARTICLE_MIDLIFE:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsArticleMidlife.ERR_GET_ARTICLE_MIDLIFE:
		return {
			...state,
			data: [],
			status: "fetch_error",
			message: "Error...",
		};
	default:
		return { ...state };

	}

}

export default storeArticleMidlife;
