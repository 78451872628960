export const ActionsCommentArticle = {
	GET_COMMENT_ARTICLE: "get_comment_article",
	LOAD_GET_COMMENT_ARTICLE: "load_get_comment_article",
	RES_GET_COMMENT_ARTICLE: "res_get_comment_article",
	ERR_GET_COMMENT_ARTICLE: "err_get_comment_article",
	HYDRATE: "HYDRATE",
};

export function failureCommentArticle(err) {

	return {
		type: ActionsCommentArticle.ERR_GET_COMMENT_ARTICLE,
		err,
	};

}

export function loadCommentArticle(params = {}) {

	return {
		type: ActionsCommentArticle.GET_COMMENT_ARTICLE,
		...params,
	};

}

export function loadGetCommentArticle(params = {}) {

	return {
		type: ActionsCommentArticle.LOAD_GET_COMMENT_ARTICLE,
		...params,
	};

}

export function loadCommentArticleSuccess(data) {

	return {
		type: ActionsCommentArticle.RES_GET_COMMENT_ARTICLE,
		data,
	};

}
