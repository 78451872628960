import { HYDRATE } from "next-redux-wrapper";
import { ActionsArticleRelation } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeArticleRelation(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeArticleRelation };
	case ActionsArticleRelation.LOAD_GET_ARTICLE_RELATION:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsArticleRelation.RES_GET_ARTICLE_RELATION:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsArticleRelation.ERR_GET_ARTICLE_RELATION:
		return {
			...state,
			status: "fetch_error",
			message: "Error...",
			data: [],
		};
	default:
		return { ...state };

	}

}

export default storeArticleRelation;
