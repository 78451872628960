export const ActionsArticleRelation = {
	GET_ARTICLE_RELATION: "get_article_relation",
	LOAD_GET_ARTICLE_RELATION: "load_get_article_relation",
	RES_GET_ARTICLE_RELATION: "res_get_article_relation",
	ERR_GET_ARTICLE_RELATION: "err_get_article_relation",
	HYDRATE: "HYDRATE",
};

export function failureArticleRelation(err) {

	return {
		type: ActionsArticleRelation.ERR_GET_ARTICLE_RELATION,
		err,
	};

}

export function loadArticleRelation(params = {}) {

	return {
		type: ActionsArticleRelation.GET_ARTICLE_RELATION,
		...params,
	};

}

export function loadGetArticleRelation(params = {}) {

	return {
		type: ActionsArticleRelation.LOAD_GET_ARTICLE_RELATION,
		...params,
	};

}

export function loadArticleRelationSuccess(data) {

	return {
		type: ActionsArticleRelation.RES_GET_ARTICLE_RELATION,
		data,
	};

}
