export const ActionsArticleDate = {
	GET_ARTICLE_DATE: "get_article_date",
	LOAD_GET_ARTICLE_DATE: "load_get_article_date",
	RES_GET_ARTICLE_DATE: "res_get_article_date",
	ERR_GET_ARTICLE_DATE: "err_get_article_date",
	HYDRATE: "HYDRATE",
};

export function failureArticleDate(err) {

	return {
		type: ActionsArticleDate.ERR_GET_ARTICLE_DATE,
		err,
	};

}

export function loadArticleDate(params = {}) {

	return {
		type: ActionsArticleDate.GET_ARTICLE_DATE,
		...params,
	};

}

export function loadGetArticleDate(params = {}) {

	return {
		type: ActionsArticleDate.LOAD_GET_ARTICLE_DATE,
		...params,
	};

}

export function loadArticleDateSuccess(data) {

	return {
		type: ActionsArticleDate.RES_GET_ARTICLE_DATE,
		data,
	};

}
