import {
	call, put, takeEvery, race, delay,
} from "redux-saga/effects";
import {
	ActionsSearchPage,
	failureSearchPage,
	loadSearchPageSuccess,
	loadGetSearchPage,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataSearchPage(params = {}) {

	try {

		yield put(loadGetSearchPage());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/searchPage`;
		const headers = {
			...Env.HEADERS,
			page: params.page || 1,
			limit: params.limit || 10,
			search: params.search || " ",
		};
		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureSearchPage(yield "Error Timeout..."));

		} else {

			const dataSearchPage = yield response.json();

			if (response.status !== 200) {

				yield put(failureSearchPage(yield response.statusText));

			} else {

				yield put(loadSearchPageSuccess(yield dataSearchPage));

			}

		}

	} catch (err) {

		yield put(failureSearchPage(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsSearchPage.GET_SEARCH_PAGE, loadDataSearchPage);

}
