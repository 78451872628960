import {
	call, delay, race, put, takeEvery,
} from "redux-saga/effects";
import {
	ActionsArticleCampaign,
	failureArticleCampaign,
	loadArticleCampaignSuccess,
	loadGetArticleCampaign,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataArticleCampaign(params = {}) {

	try {

		yield put(loadGetArticleCampaign());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getArticleCampaign`;
		const headers = {
			...Env.HEADERS,
			limit: params.limit || 5,
		};
		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureArticleCampaign(yield "Error Timeout..."));

		} else {

			const dataArticleCampaign = yield response.json();

			if (response.status !== 200) {

				yield put(failureArticleCampaign(yield response.statusText));

			} else {

				yield put(loadArticleCampaignSuccess(yield dataArticleCampaign));

			}

		}

	} catch (err) {

		yield put(failureArticleCampaign(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsArticleCampaign.GET_ARTICLE_CAMPAIGN, loadDataArticleCampaign);

}
