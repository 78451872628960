export const ActionsArticleCategory = {
	GET_ARTICLE_CATEGORY: "get_article_category",
	LOAD_GET_ARTICLE_CATEGORY: "load_get_article_category",
	RES_GET_ARTICLE_CATEGORY: "res_get_article_category",
	ERR_GET_ARTICLE_CATEGORY: "err_get_article_category",
	HYDRATE: "HYDRATE",
};

export function failureArticleCategory(err) {

	return {
		type: ActionsArticleCategory.ERR_GET_ARTICLE_CATEGORY,
		err,
	};

}

export function loadArticleCategory(params = {}) {

	return {
		type: ActionsArticleCategory.GET_ARTICLE_CATEGORY,
		...params,
	};

}

export function loadGetArticleCategory(data) {

	return {
		type: ActionsArticleCategory.LOAD_GET_ARTICLE_CATEGORY,
		data,
	};

}

export function loadArticleCategorySuccess(data) {

	return {
		type: ActionsArticleCategory.RES_GET_ARTICLE_CATEGORY,
		data,
	};

}
