export const ActionsArticleCampaign = {
	GET_ARTICLE_CAMPAIGN: "get_article_campaign",
	LOAD_GET_ARTICLE_CAMPAIGN: "load_get_article_campaign",
	RES_GET_ARTICLE_CAMPAIGN: "res_get_article_campaign",
	ERR_GET_ARTICLE_CAMPAIGN: "err_get_article_campaign",
	HYDRATE: "HYDRATE",
};

export function failureArticleCampaign(err) {

	return {
		type: ActionsArticleCampaign.ERR_GET_ARTICLE_CAMPAIGN,
		err,
	};

}

export function loadArticleCampaign(params = {}) {

	return {
		type: ActionsArticleCampaign.GET_ARTICLE_CAMPAIGN,
		...params,
	};

}

export function loadGetArticleCampaign(params = {}) {

	return {
		type: ActionsArticleCampaign.LOAD_GET_ARTICLE_CAMPAIGN,
		...params,
	};

}

export function loadArticleCampaignSuccess(data) {

	return {
		type: ActionsArticleCampaign.RES_GET_ARTICLE_CAMPAIGN,
		data,
	};

}
