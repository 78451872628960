import {
	call, put, takeEvery,
} from "redux-saga/effects";
import {
	ActionsArticleDetail,
	failureArticleDetail,
	loadArticleDetailSuccess,
	loadGetArticleDetail,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataArticleDetail(params = {}) {

	try {

		yield put(loadGetArticleDetail());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getArticleDetail`;
		const headers = {
			...Env.HEADERS,
			slug: params.slug,
			page: params.page || 1,
		};
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const dataArticleDetail = yield response.json();

		if (response.status !== 200) {

			yield put(failureArticleDetail(yield response.statusText));

		} else {

			yield put(loadArticleDetailSuccess(yield dataArticleDetail));

		}

	} catch (err) {

		yield put(failureArticleDetail(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsArticleDetail.GET_ARTICLE_DETAIL, loadDataArticleDetail);

}
