import {
	call, put, takeEvery, race, delay,
} from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsArticleMidlife,
	failureArticleMidlife,
	loadArticleMidlifeSuccess,
	loadGetArticleMidlife,
} from "./actions";

function* loadDataArticleMidlife(queryParams = {}) {

	try {

		yield put(loadGetArticleMidlife());
		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getArticleTags`;
		const headers = {
			...Env.HEADERS,
			slug: process.env.NEXT_PUBLIC_MIDLIFE_TAG,
			page: queryParams.page,
		};
		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureArticleMidlife(yield "Error Timeout..."));

		} else {

			const dataArticleMidlife = yield response.json();

			if (response.status !== 200) {

				yield put(failureArticleMidlife(yield response.statusText));

			} else {

				yield put(loadArticleMidlifeSuccess(yield dataArticleMidlife));

			}

		}

	} catch (err) {

		yield put(failureArticleMidlife(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsArticleMidlife.GET_ARTICLE_MIDLIFE, loadDataArticleMidlife);

}
