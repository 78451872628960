import { HYDRATE } from "next-redux-wrapper";
import { ActionsArticleCategory } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeArticleCategory(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeArticleCategory };
	case ActionsArticleCategory.LOAD_GET_ARTICLE_CATEGORY:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsArticleCategory.RES_GET_ARTICLE_CATEGORY:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsArticleCategory.ERR_GET_ARTICLE_CATEGORY:
		return {
			...state,
			status: "fetch_error",
			message: "Error...",
			data: { data: [] },
		};
	default:
		return { ...state };

	}

}

export default storeArticleCategory;
