import { HYDRATE } from "next-redux-wrapper";
import { ActionsSearchPage } from "./actions";

const initialState = { data: [], status: "fetch_init", message: "" };

function storeSearchPage(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeSearchPage };
	case ActionsSearchPage.LOAD_GET_SEARCH_PAGE:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsSearchPage.RES_GET_SEARCH_PAGE:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsSearchPage.ERR_GET_SEARCH_PAGE:
		return {
			...state,
			data: [],
			status: "fetch_error",
			message: "Error...",
		};
	default:
		return { ...state };

	}

}

export default storeSearchPage;
