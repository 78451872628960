export const ActionsArticleParenting = {
	GET_ARTICLE_PARENTING: "get_article_parenting",
	LOAD_GET_ARTICLE_PARENTING: "load_get_article_parenting",
	RES_GET_ARTICLE_PARENTING: "res_get_article_parenting",
	ERR_GET_ARTICLE_PARENTING: "err_get_article_parenting",
	HYDRATE: "HYDRATE",
};

export function failureArticleParenting(err) {

	return {
		type: ActionsArticleParenting.ERR_GET_ARTICLE_PARENTING,
		err,
	};

}

export function loadArticleParenting(params = {}) {

	return {
		type: ActionsArticleParenting.GET_ARTICLE_PARENTING,
		...params,
	};

}

export function loadGetArticleParenting(params = {}) {

	return {
		type: ActionsArticleParenting.LOAD_GET_ARTICLE_PARENTING,
		...params,
	};

}

export function loadArticleParentingSuccess(data) {

	return {
		type: ActionsArticleParenting.RES_GET_ARTICLE_PARENTING,
		data,
	};

}
