import { HYDRATE } from "next-redux-wrapper";
import { ActionsArticleTags } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeArticleTags(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeArticleTags };
	case ActionsArticleTags.LOAD_GET_ARTICLE_TAGS:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsArticleTags.RES_GET_ARTICLE_TAGS:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsArticleTags.ERR_GET_ARTICLE_TAGS:
		return {
			...state,
			status: "fetch_error",
			message: "Error...",
			data: { data: [] },
		};
	default:
		return { ...state };

	}

}

export default storeArticleTags;
