import {
	call, put, takeEvery, race, delay,
} from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsYoutubeVideo,
	failureYoutubeVideo,
	loadYoutubeVideoSuccess,
	loadGetYoutubeVideo,
} from "./actions";

function* loadDataYoutubeVideo(queryParams = {}) {

	try {

		yield put(loadGetYoutubeVideo());
		const endpointMedium = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getYoutubeVideo?${queryParams?.query?.medium ?? ""}`;
		const endpointLong = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getYoutubeVideo?${queryParams?.query?.long ?? ""}`;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const { timeout: timeoutMedium, response: responseMedium } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpointMedium, options),
		});

		const { timeout: timeoutLong, response: responseLong } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpointLong, options),
		});

		if (timeoutMedium || timeoutLong) {

			yield put(failureYoutubeVideo(yield "Error Timeout..."));

		} else {

			const dataYoutubeVideoMedium = yield responseMedium.json();
			const dataYoutubeVideoLong = yield responseLong.json();

			if (responseMedium.status !== 200 || responseLong.status !== 200) {

				yield put(failureYoutubeVideo(yield responseMedium.statusText));

			} else {

				const transformList = [...dataYoutubeVideoMedium?.items, ...dataYoutubeVideoLong?.items];
				const sortedList = transformList.sort((a, b) => {

					const dateA = new Date(a.snippet?.publishedAt);
					const dateB = new Date(b.snippet?.publishedAt);
					return dateB - dateA;

				});

				const transformResponse = { items: sortedList.slice(0, 7) };
				yield put(loadYoutubeVideoSuccess(yield transformResponse));

			}

		}

	} catch (err) {

		yield put(failureYoutubeVideo(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsYoutubeVideo.GET_YOUTUBE_VIDEO, loadDataYoutubeVideo);

}
