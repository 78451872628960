import { HYDRATE } from "next-redux-wrapper";
import { ActionsArticleWork } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeArticleWork(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeArticleWork };
	case ActionsArticleWork.LOAD_GET_ARTICLE_WORK:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsArticleWork.RES_GET_ARTICLE_WORK:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsArticleWork.ERR_GET_ARTICLE_WORK:
		return {
			...state,
			status: "fetch_error",
			message: "Error...",
			data: [],
		};
	default:
		return { ...state };

	}

}

export default storeArticleWork;
