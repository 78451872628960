import { HYDRATE } from "next-redux-wrapper";
import { ActionsArticleRelated } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeArticleRelated(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeArticleRelated };
	case ActionsArticleRelated.LOAD_GET_ARTICLE_RELATED:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsArticleRelated.RES_GET_ARTICLE_RELATED:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsArticleRelated.ERR_GET_ARTICLE_RELATED:
		return {
			...state,
			status: "fetch_error",
			message: "Error...",
		};
	default:
		return { ...state };

	}

}

export default storeArticleRelated;
