export const ActionsMenuDrawer = {
	GET_MENU_DRAWER: "get_menu_drawer",
	LOAD_GET_MENU_DRAWER: "load_get_menu_drawer",
	RES_GET_MENU_DRAWER: "res_get_menu_drawer",
	ERR_GET_MENU_DRAWER: "err_get_menu_drawer",
	HYDRATE: "HYDRATE",
};

export function failureMenuDrawer(err) {

	return {
		type: ActionsMenuDrawer.ERR_GET_MENU_DRAWER,
		err,
	};

}

export function loadMenuDrawer(params = {}) {

	return {
		type: ActionsMenuDrawer.GET_MENU_DRAWER,
		...params,
	};

}

export function loadGetMenuDrawer(params = {}) {

	return {
		type: ActionsMenuDrawer.LOAD_GET_MENU_DRAWER,
		...params,
	};

}

export function loadMenuDrawerSuccess(data) {

	return {
		type: ActionsMenuDrawer.RES_GET_MENU_DRAWER,
		data,
	};

}
