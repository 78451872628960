import {
	call, put, takeEvery, race, delay,
} from "redux-saga/effects";
import {
	ActionsDiscoverVideo,
	failureDiscoverVideo,
	loadDiscoverVideoSuccess,
	loadGetDiscoverVideo,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataDiscoverVideo(params = {}) {

	try {

		yield put(loadGetDiscoverVideo());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getDiscoverVideo`;
		const headers = { ...Env.HEADERS, page: params.page };
		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureDiscoverVideo(yield "Error Timeout..."));

		} else {

			const dataDiscoverVideo = yield response.json();

			if (response.status !== 200) {

				yield put(failureDiscoverVideo(yield response.statusText));

			} else {

				yield put(loadDiscoverVideoSuccess(yield dataDiscoverVideo));

			}

		}

	} catch (err) {

		yield put(failureDiscoverVideo(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsDiscoverVideo.GET_DISCOVER_VIDEO, loadDataDiscoverVideo);

}
