import {
	call, put, takeEvery, race, delay,
} from "redux-saga/effects";
import {
	ActionsArticleCategory,
	failureArticleCategory,
	loadArticleCategorySuccess,
	loadGetArticleCategory,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataArticleCategory(params = {}) {

	try {

		yield put(loadGetArticleCategory());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getArticleCategory`;
		const headers = { ...Env.HEADERS, slug: params.slug, page: params.page };
		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureArticleCategory(yield "Error Timeout..."));

		} else {

			const dataArticleCategory = yield response.json();

			if (response.status !== 200) {

				yield put(failureArticleCategory(yield response.statusText));

			} else {

				yield put(loadArticleCategorySuccess(yield dataArticleCategory));

			}

		}

	} catch (err) {

		yield put(failureArticleCategory(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsArticleCategory.GET_ARTICLE_CATEGORY, loadDataArticleCategory);

}
