import {
	call, put, takeEvery,
} from "redux-saga/effects";
import {
	ActionsArticleRelated,
	failureArticleRelated,
	loadArticleRelatedSuccess,
	loadGetArticleRelated,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataArticleRelated(params = {}) {

	try {

		yield put(loadGetArticleRelated());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getArticleRelated`;
		const headers = { ...Env.HEADERS, slug: params.slug, limit: params.limit };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const dataArticleRelated = yield response.json();

		if (response.status !== 200) {

			yield put(failureArticleRelated(yield response.statusText));

		} else {

			yield put(loadArticleRelatedSuccess(yield dataArticleRelated));

		}

	} catch (err) {

		yield put(failureArticleRelated(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsArticleRelated.GET_ARTICLE_RELATED, loadDataArticleRelated);

}
