import { fork } from "redux-saga/effects";
import { dispatchSelf } from "@fdn/profile_store";
import dispatchArticlePopular from "../ArticlePopular/lib/fetch";
import dispatchArticleZone from "../ArticleZone/lib/fetch";
import dispatchArticleMost from "../ArticleMost/lib/fetch";
import dispatchArticleCampaign from "../ArticleCampaign/lib/fetch";
import dispatchArticleParenting from "../ArticleParenting/lib/fetch";
import dispatchDiscoverVideo from "../DiscoverVideo/lib/fetch";
import dispatchArticleEducation from "../ArticleEducation/lib/fetch";
import dispatchArticleRelation from "../ArticleRelation/lib/fetch";
import dispatchArticleWork from "../ArticleWork/lib/fetch";
import dispatchArticleDetail from "../ArticleDetail/lib/fetch";
import dispatchArticlePreview from "../ArticlePreview/lib/fetch";
import dispatchArticleRelated from "../ArticleRelated/lib/fetch";
import dispatchArticleCategory from "../ArticleCategory/lib/fetch";
import dispatchArticleTags from "../ArticleTags/lib/fetch";
import dispatchArticleDate from "../ArticleDate/lib/fetch";
import dispatchArticleAuthor from "../ArticleAuthor/lib/fetch";
import dispatchQuiz from "../Quiz/lib/fetch";
import dispatchReview from "../Review/lib/fetch";
import dispatchCommentArticle from "../CommentArticle/lib/fetch";
import dispatchSearchArticles from "../SearchArticles/lib/fetch";
import dispatchMenuNavbar from "../MenuNavbar/lib/fetch";
import dispatchMenuDrawer from "../MenuDrawer/lib/fetch";
import dispatchSearchPage from "../SearchPage/lib/fetch";
import dispatchWallet from "../AlloWallet/lib/fetch";
import dispatchPoint from "../AlloPoint/lib/fetch";
import dispatchSynergyMedia from "../SynergyMedia/lib/fetch";
import dispatchYoutubeVideo from "../YoutubeVideo/lib/fetch";
import dispatchArticleMidlife from "../ArticleMidlife/lib/fetch";

export default function* rootSaga() {

	yield fork(dispatchSelf);
	yield fork(dispatchArticlePopular);
	yield fork(dispatchArticleZone);
	yield fork(dispatchArticleMost);
	yield fork(dispatchArticleCampaign);
	yield fork(dispatchArticleParenting);
	yield fork(dispatchDiscoverVideo);
	yield fork(dispatchArticleEducation);
	yield fork(dispatchArticleRelation);
	yield fork(dispatchArticleWork);
	yield fork(dispatchArticleDetail);
	yield fork(dispatchArticleRelated);
	yield fork(dispatchArticleCategory);
	yield fork(dispatchArticleTags);
	yield fork(dispatchArticleDate);
	yield fork(dispatchArticleAuthor);
	yield fork(dispatchQuiz);
	yield fork(dispatchReview);
	yield fork(dispatchCommentArticle);
	yield fork(dispatchSearchArticles);
	yield fork(dispatchMenuNavbar);
	yield fork(dispatchMenuDrawer);
	yield fork(dispatchSearchPage);
	yield fork(dispatchArticlePreview);
	yield fork(dispatchWallet);
	yield fork(dispatchPoint);
	yield fork(dispatchSynergyMedia);
	yield fork(dispatchYoutubeVideo);
	yield fork(dispatchArticleMidlife);

}
