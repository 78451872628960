export const ActionsReview = {
	GET_REVIEW: "get_review",
	LOAD_GET_REVIEW: "load_get_review",
	RES_GET_REVIEW: "res_get_review",
	ERR_GET_REVIEW: "err_get_review",
	HYDRATE: "HYDRATE",
};

export function failureReview(err) {

	return {
		type: ActionsReview.ERR_GET_REVIEW,
		err,
	};

}

export function loadReview(params = {}) {

	return {
		type: ActionsReview.GET_REVIEW,
		...params,
	};

}

export function loadGetReview(params = {}) {

	return {
		type: ActionsReview.LOAD_GET_REVIEW,
		...params,
	};

}

export function loadReviewSuccess(data) {

	return {
		type: ActionsReview.RES_GET_REVIEW,
		data,
	};

}
