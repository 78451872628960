import {
	call, put, takeEvery,
} from "redux-saga/effects";
import {
	ActionsArticlePreview,
	failureArticlePreview,
	loadArticlePreviewSuccess,
	loadGetArticlePreview,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataArticlePreview(params = {}) {

	try {

		yield put(loadGetArticlePreview());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getArticlePreview`;
		const headers = {
			...Env.HEADERS,
			slug: params.slug,
			page: params.page || 1,
		};
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const dataArticlePreview = yield response.json();

		if (response.status !== 200) {

			yield put(failureArticlePreview(yield response.statusText));

		} else {

			yield put(loadArticlePreviewSuccess(yield dataArticlePreview));

		}

	} catch (err) {

		yield put(failureArticlePreview(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsArticlePreview.GET_ARTICLE_PREVIEW, loadDataArticlePreview);

}
