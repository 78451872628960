export default {

	// Headers
	HEADERS: {
		key: process.env.NEXT_PUBLIC_KEY_API,
		version: "1.5",
		device: "3",
		"content-type": "application/json",
	},
	// Api timeout
	REQUEST_TIMEOUT: 5000,
	WHATS_ON_FD_YOUTUBE_LINK: process.env.NEXT_PUBLIC_YOUTUBE_WHATSONFD_LINK,
};
