export const ActionsArticleMost = {
	GET_ARTICLE_MOST: "get_article_most",
	LOAD_GET_ARTICLE_MOST: "load_get_article_most",
	RES_GET_ARTICLE_MOST: "res_get_article_most",
	ERR_GET_ARTICLE_MOST: "err_get_article_most",
	HYDRATE: "HYDRATE",
};

export function failureArticleMost(err) {

	return {
		type: ActionsArticleMost.ERR_GET_ARTICLE_MOST,
		err,
	};

}

export function loadArticleMost(params = {}) {

	return {
		type: ActionsArticleMost.GET_ARTICLE_MOST,
		...params,
	};

}

export function loadGetArticleMost(params = {}) {

	return {
		type: ActionsArticleMost.LOAD_GET_ARTICLE_MOST,
		...params,
	};

}

export function loadArticleMostSuccess(data) {

	return {
		type: ActionsArticleMost.RES_GET_ARTICLE_MOST,
		data,
	};

}
