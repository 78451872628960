export const ActionsArticleZone = {
	GET_ARTICLE_ZONE: "get_article_zone",
	LOAD_GET_ARTICLE_ZONE: "load_get_article_zone",
	RES_GET_ARTICLE_ZONE: "res_get_article_zone",
	ERR_GET_ARTICLE_ZONE: "err_get_article_zone",
	HYDRATE: "HYDRATE",
};

export function failureArticleZone(err) {

	return {
		type: ActionsArticleZone.ERR_GET_ARTICLE_ZONE,
		err,
	};

}

export function loadArticleZone(params = {}) {

	return {
		type: ActionsArticleZone.GET_ARTICLE_ZONE,
		...params,
	};

}

export function loadGetArticleZone(params = {}) {

	return {
		type: ActionsArticleZone.LOAD_GET_ARTICLE_ZONE,
		...params,
	};

}

export function loadArticleZoneSuccess(data) {

	return {
		type: ActionsArticleZone.RES_GET_ARTICLE_ZONE,
		data,
	};

}
