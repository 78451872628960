import {
	call, put, takeEvery, race, delay,
} from "redux-saga/effects";
import {
	ActionsQuiz,
	failureQuiz,
	loadQuizSuccess,
	loadGetQuiz,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataQuiz(params = {}) {

	try {

		yield put(loadGetQuiz());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getQuiz`;
		const headers = { ...Env.HEADERS, page: params.page };
		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureQuiz(yield "Error Timeout..."));

		} else {

			const dataQuiz = yield response.json();

			if (response.status !== 200) {

				yield put(failureQuiz(yield response.statusText));

			} else {

				yield put(loadQuizSuccess(yield dataQuiz));

			}

		}

	} catch (err) {

		yield put(failureQuiz(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsQuiz.GET_QUIZ, loadDataQuiz);

}
