import { call, put, takeEvery } from "redux-saga/effects";
import {
	ActionsSearchArticles,
	API_SEARCH_ARTICLES,
	failureSearchArticles,
	loadSearchArticlesSuccess,
} from "./actions";

function* loadDataSearchArticles(params = {}) {

	try {

		const endpoint = `${API_SEARCH_ARTICLES}?search=${params.payload.keyword}`;
		const headers = params.payload.is_server
			? { ...params.payload.headers }
			: {
				...params.payload.headers,
				// endpoint: api_endpoint,
			};
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (response.status >= 200 && response.status < 300) {

			const data = yield response.json();

			const articlesData = [];
			data?.data?.map((item) => articlesData.push({
				id: item?.id,
				date: item?.created_at,
				image: item?.image,
				url: item?.url,
				title: item?.title,
				text: item?.excerpt,
				category: {
					name: item?.category?.length > 0 ? item?.category?.[0]?.name : "",
					slug: item?.category_slug,
					slugs: item?.category_slugs,
				},
				author: {
					username: item?.author?.username,
					fullname: item?.author?.name,
				},
			}));
			data.data = articlesData;

			yield put(loadSearchArticlesSuccess(data));

		} else {

			yield put(failureSearchArticles);

		}

	} catch (err) {

		yield put(failureSearchArticles(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(
		ActionsSearchArticles.GET_SEARCH_ARTICLES,
		loadDataSearchArticles,
	);

}
