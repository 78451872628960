export const ActionsArticleEducation = {
	GET_ARTICLE_EDUCATION: "get_article_education",
	LOAD_GET_ARTICLE_EDUCATION: "load_get_article_education",
	RES_GET_ARTICLE_EDUCATION: "res_get_article_education",
	ERR_GET_ARTICLE_EDUCATION: "err_get_article_education",
	HYDRATE: "HYDRATE",
};

export function failureArticleEducation(err) {

	return {
		type: ActionsArticleEducation.ERR_GET_ARTICLE_EDUCATION,
		err,
	};

}

export function loadArticleEducation(params = {}) {

	return {
		type: ActionsArticleEducation.GET_ARTICLE_EDUCATION,
		...params,
	};

}

export function loadGetArticleEducation(params = {}) {

	return {
		type: ActionsArticleEducation.LOAD_GET_ARTICLE_EDUCATION,
		...params,
	};

}

export function loadArticleEducationSuccess(data) {

	return {
		type: ActionsArticleEducation.RES_GET_ARTICLE_EDUCATION,
		data,
	};

}
