export const ActionsArticleTags = {
	GET_ARTICLE_TAGS: "get_article_tags",
	LOAD_GET_ARTICLE_TAGS: "load_get_article_tags",
	RES_GET_ARTICLE_TAGS: "res_get_article_tags",
	ERR_GET_ARTICLE_TAGS: "err_get_article_tags",
	HYDRATE: "HYDRATE",
};

export function failureArticleTags(err) {

	return {
		type: ActionsArticleTags.ERR_GET_ARTICLE_TAGS,
		err,
	};

}

export function loadArticleTags(params = {}) {

	return {
		type: ActionsArticleTags.GET_ARTICLE_TAGS,
		...params,
	};

}

export function loadGetArticleTags(data) {

	return {
		type: ActionsArticleTags.LOAD_GET_ARTICLE_TAGS,
		data,
	};

}

export function loadArticleTagsSuccess(data) {

	return {
		type: ActionsArticleTags.RES_GET_ARTICLE_TAGS,
		data,
	};

}
