export const ActionsArticlePreview = {
	GET_ARTICLE_PREVIEW: "get_article_preview",
	LOAD_GET_ARTICLE_PREVIEW: "load_get_article_preview",
	RES_GET_ARTICLE_PREVIEW: "res_get_article_preview",
	ERR_GET_ARTICLE_PREVIEW: "err_get_article_preview",
	HYDRATE: "HYDRATE",
};

export function failureArticlePreview(err) {

	return {
		type: ActionsArticlePreview.ERR_GET_ARTICLE_PREVIEW,
		err,
	};

}

export function loadArticlePreview(params = {}) {

	return {
		type: ActionsArticlePreview.GET_ARTICLE_PREVIEW,
		...params,
	};

}

export function loadGetArticlePreview(params = {}) {

	return {
		type: ActionsArticlePreview.LOAD_GET_ARTICLE_PREVIEW,
		...params,
	};

}

export function loadArticlePreviewSuccess(data) {

	return {
		type: ActionsArticlePreview.RES_GET_ARTICLE_PREVIEW,
		data,
	};

}
