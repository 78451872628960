export const ActionsMenuNavbar = {
	GET_MENU_NAVBAR: "get_menu_navbar",
	LOAD_GET_MENU_NAVBAR: "load_get_menu_navbar",
	RES_GET_MENU_NAVBAR: "res_get_menu_navbar",
	ERR_GET_MENU_NAVBAR: "err_get_menu_navbar",
	HYDRATE: "HYDRATE",
};

export function failureMenuNavbar(err) {

	return {
		type: ActionsMenuNavbar.ERR_GET_MENU_NAVBAR,
		err,
	};

}

export function loadMenuNavbar(params = {}) {

	return {
		type: ActionsMenuNavbar.GET_MENU_NAVBAR,
		...params,
	};

}

export function loadGetMenuNavbar(params = {}) {

	return {
		type: ActionsMenuNavbar.LOAD_GET_MENU_NAVBAR,
		...params,
	};

}

export function loadMenuNavbarSuccess(data) {

	return {
		type: ActionsMenuNavbar.RES_GET_MENU_NAVBAR,
		data,
	};

}
