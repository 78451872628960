import {
	call, put, race, delay, takeEvery,
} from "redux-saga/effects";
import {
	ActionsArticleZone,
	failureArticleZone,
	loadArticleZoneSuccess,
	loadGetArticleZone,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataArticleZone(params = {}) {

	try {

		yield put(loadGetArticleZone());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getArticleZone`;
		const headers = {
			...Env.HEADERS,
			page: params.page,
			limit: params.limit || 4,
		};
		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(Env.REQUEST_TIMEOUT),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureArticleZone(yield "Error Timeout..."));

		} else {

			const dataArticleZone = yield response.json();

			if (response.status !== 200) {

				yield put(failureArticleZone(yield response.statusText));

			} else {

				yield put(loadArticleZoneSuccess(yield dataArticleZone));

			}

		}

	} catch (err) {

		yield put(failureArticleZone(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsArticleZone.GET_ARTICLE_ZONE, loadDataArticleZone);

}
