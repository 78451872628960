import {
	call, put, takeEvery,
} from "redux-saga/effects";
import {
	ActionsCommentArticle,
	failureCommentArticle,
	loadCommentArticleSuccess,
	loadGetCommentArticle,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataCommentArticle(params = {}) {

	try {

		yield put(loadGetCommentArticle());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getCommentArticle`;
		const headers = {
			...Env.HEADERS,
			limit: params.limit || 3,
			slug: params.slug,
			id: params.id || undefined,
		};
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const dataCommentArticle = yield response.json();

		if (response.status !== 200) {

			yield put(failureCommentArticle(yield response.statusText));

		} else {

			yield put(loadCommentArticleSuccess(yield dataCommentArticle));

		}

	} catch (err) {

		yield put(failureCommentArticle(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsCommentArticle.GET_COMMENT_ARTICLE, loadDataCommentArticle);

}
