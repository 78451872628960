import { HYDRATE } from "next-redux-wrapper";
import { ActionsMenuNavbar } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeMenuNavbar(state = initialState, action) {

	switch (action.type) {

	case HYDRATE:
		return { ...state, ...action.payload.storeMenuNavbar };
	case ActionsMenuNavbar.LOAD_GET_MENU_NAVBAR:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsMenuNavbar.RES_GET_MENU_NAVBAR:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsMenuNavbar.ERR_GET_MENU_NAVBAR:
		return {
			...state,
			status: "fetch_error",
			message: "Error...",
		};
	default:
		return { ...state };

	}

}

export default storeMenuNavbar;
