import {
	call, put, takeEvery,
} from "redux-saga/effects";
import {
	ActionsMenuDrawer,
	failureMenuDrawer,
	loadMenuDrawerSuccess,
	loadGetMenuDrawer,
} from "./actions";
import Env from "../../../consts/env";

function* loadDataMenuDrawer(params = {}) {

	try {

		yield put(loadGetMenuDrawer());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getMenuDrawer`;
		const headers = { ...Env.HEADERS, page: params.page };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const dataMenuDrawer = yield response.json();

		if (response.status !== 200) {

			yield put(failureMenuDrawer(yield response.statusText));

		} else {

			yield put(loadMenuDrawerSuccess(yield dataMenuDrawer));

		}

	} catch (err) {

		yield put(failureMenuDrawer(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsMenuDrawer.GET_MENU_DRAWER, loadDataMenuDrawer);

}
