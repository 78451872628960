/* eslint-disable max-len */
import {
	call, delay, put, race, takeEvery,
} from "redux-saga/effects";
import Env from "../../../consts/env";
import {
	ActionsSynergyMedia,
	failureSynergyMedia, loadGetSynergyMedia, loadSynergyMediaSuccess,
} from "./actions";

function* loadDataSynergyMedia(params = {}) {

	try {

		yield put(loadGetSynergyMedia({ typeSynergy: params.payload.type }));

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getSynergyMedia?type=${params.payload.type}`;

		const headers = { ...Env.HEADERS, page: params.payload.page };
		const method = "GET";
		const options = { headers, method };

		const { timeout, response } = yield race({
			timeout: delay(30000),
			response: call(fetch, endpoint, options),
		});

		if (timeout) {

			yield put(failureSynergyMedia(yield "Error Timeout...", { typeSynergy: params.payload.type }));

		} else {

			const dataSynergyMedia = yield response.json();

			if (response.status !== 200) {

				yield put(failureSynergyMedia(yield response.statusText, { typeSynergy: params.payload.type }));

			} else {

				yield put(loadSynergyMediaSuccess(yield dataSynergyMedia, { typeSynergy: params.payload.type }));

			}

		}

	} catch (err) {

		yield put(failureSynergyMedia(err, { typeSynergy: params.payload.type }));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsSynergyMedia.GET_SYNERGY_MEDIA, loadDataSynergyMedia);

}
