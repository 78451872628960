export const ActionsDiscoverVideo = {
	GET_DISCOVER_VIDEO: "get_discover_video",
	LOAD_GET_DISCOVER_VIDEO: "load_get_discover_video",
	RES_GET_DISCOVER_VIDEO: "res_get_discover_video",
	ERR_GET_DISCOVER_VIDEO: "err_get_discover_video",
	HYDRATE: "HYDRATE",
};

export function failureDiscoverVideo(err) {

	return {
		type: ActionsDiscoverVideo.ERR_GET_DISCOVER_VIDEO,
		err,
	};

}

export function loadDiscoverVideo(params = {}) {

	return {
		type: ActionsDiscoverVideo.GET_DISCOVER_VIDEO,
		...params,
	};

}

export function loadGetDiscoverVideo(params = {}) {

	return {
		type: ActionsDiscoverVideo.LOAD_GET_DISCOVER_VIDEO,
		...params,
	};

}

export function loadDiscoverVideoSuccess(data) {

	return {
		type: ActionsDiscoverVideo.RES_GET_DISCOVER_VIDEO,
		data,
	};

}
